import React, { useEffect, useRef, useState } from "react";

import LinkTransition from './linkTransition';
import IconArrow from '../assets/icoArrowRightYellow.inline.svg';

const FadeText = () => {

    let phrases = [
        "Traduzir marcas em conceitos que inspiram, conectam e geram experiências positivas aos seus públicos.",
        "Transformar conceitos em marcas que inspiram e conectam.",
        "Comunicar de forma clara, exaltando os benefícios e os diferenciais da marca.",
        "Usar a comunicação para fazer a diferença na vida das pessoas. ",
        "Contar histórias que criam conexões de vida com seus clientes.",
        "Exaltar a história de marcas inspiradoras para transformar clientes em fãs.",
    ]

    const dynamicText = useRef(null)

    const [count, setCount] = useState(0);
    const [dynamicPhrases, setDynamicPhrases] = useState(phrases[count]);

    useEffect(() => {

        setTimeout(() => {
            dynamicText.current?.classList.toggle("case-text-dynamic");
            count < (phrases.length - 1) ? setCount(count + 1) : setCount(0);
        }, 5000);

        setTimeout(() => {
            setDynamicPhrases(phrases[count]);
            dynamicText.current?.classList.toggle("case-text-dynamic")
        }, 500);

    }, [count]);

    return (
        <div className="container">
            <div className="flex flex-col justify-center items-start py-24 md:py-40 md:px-52 border-t">
                <p className="text-4xl md:text-2xl mb-16 md:mb-4">Em outras palavras</p>
                <p className="text-4xl leading-normal mb-4 hidden md:block transition duration-500 ease opacity-0" ref={dynamicText}>{dynamicPhrases}</p>

                <div className="md:hidden mb-8">
                    {
                        phrases.map((el, id) => {
                            return (
                                <div data-aos="fade-right" className="mb-12 " key={id}>
                                    <p className="mb-8">{el}</p>
                                    <div className="w-20 border-b opacity-60" />
                                </div>
                            )
                        })
                    }
                </div>

                <LinkTransition
                    to="/contato"
                    className="link-transition content-center justify-end flex self-end"
                >
                    <span className=" font-light text-yellow mr-2">Entre em contato</span>
                    <IconArrow className="mt-1" />
                </LinkTransition>

            </div>
        </div>
    )
};

export default FadeText;
