import React from 'react';

const Services = () => {

    return (
        <div className="container md:border-b">
            <div className="pt-40 border-t">
                <h2 data-aos="fade-right" className="text-4xl md:mb-20">Como fazemos</h2>
            </div>
            <div className="grid grid-cols-1 border-b md:border-none md:gap-6 md:mb-40 md:grid-cols-2 md:gap-x-56 md:gap-y-20">
                <div data-aos="fade-right" className="border-slate-200 pb-20 pt-24 md:py-0 md:border-none">
                    <h3 className="text-2xl mb-10 text-yellow">Estratégia & Inovação</h3>
                    <p className="font-extralight leading-normal">Para inovar é preciso ousar. Contamos com criatividade, metodologia própria e inovação constante para que cada marca seja única no mercado.</p>
                </div>
                <div data-aos="fade-right" data-aos-delay="200" className="pb-20 pt-16 md:py-0 md:border-none">
                    <h3 className="text-2xl mb-10 text-yellow">Pesquisa & Inteligência</h3>
                    <p className='font-extralight leading-normal'>Projetos e ideias baseados em dados são os pilares para a construção e a sustentação de marcas fortes.</p>
                </div>
                <div data-aos="fade-right" data-aos-delay="400" className="pb-20 pt-16 md:py-0 md:border-none">
                    <h3 className="text-2xl mb-10 text-yellow ">Design de Experiência do Consumidor</h3>
                    <p className='font-extralight leading-normal'>Provocar novas experiências e construir conexões reais são expertises essenciais para cativar os clientes e estimular os sentidos em sua jornada.</p>
                </div>
                <div data-aos="fade-right" data-aos-delay="600" className="border-slate-200 pb-24 pt-16 md:py-0 md:border-none">
                    <h3 className="text-2xl mb-10 text-yellow ">Cultura, Conteúdo & Social</h3>
                    <p className='font-extralight leading-normal'>Para transmitir a essência da marca, é preciso provocar, engajar, dialogar e inspirar.</p>
                </div>
            </div>
        </div>
    );
};

export default Services;
