import React from "react";

import CardCase from "./cardCase";
import Data from "../data/cases.json";

const ListCases = () => {
  const delay = 100;

  return (
    <div className="container my-24 md:mb-40">
      <h2 className="text-4xl my-20 md:mb-20 md:mt-40">Cases</h2>
      <div className="grid grid-cols-1 gap-6 gap-y-20 md:gap-y-24 md:grid-cols-3">
        {Data.map((row, index) => {
          return (
            <CardCase
              key={row.title}
              image={row.image}
              title={row.title}
              link={row.link}
              description={row.description}
              delay={delay * index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ListCases;
