import React from "react";
import LinkTransition from "./linkTransition";
import LogoAtimo from "../assets/atimo.inline.svg";
import IconArrow from '../assets/icoArrowRightBlack.inline.svg';

const SingleCase = () => {
  return (
    <div data-aos='fade-in' className='single-case bg-no-repeat bg-center bg-cover'>
      <div className='container flex flex-col md:flex-row items-center justify-center my-20 gap-20 py-20 md:py-40'>
        <div className='w-full flex justify-center md:justify-start'>
          <div data-aos='fade-right' data-aos-delay="300" className='w-1/2 md:w-2/3'>
            <LogoAtimo />
          </div>
        </div>
        <div className='w-full flex justify-end px-8 md:px-0'>
          <div data-aos='fade-left' data-aos-delay="600" className='single-case-container'>
            <div className='px-6 md:px-20 py-10 md:pt-16 md:pb-20'>
              <p className='text-yellow text-lg md:text-xl mb-10'>Case</p>
              <p className='text-2xl md:text-5xl md:leading-tight'>A vida ganha movimento com a Átimo</p>
            </div>
            <div className='flex justify-end'>
              <LinkTransition
                to="/cases/atimo"
                className="flex justify-end w-max"
              >
                <span className="p-4 bg-yellow hover:opacity-80">
                  <IconArrow />
                </span>
              </LinkTransition>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SingleCase;
