import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Hero from '../components/hero'
import Services from '../components/services';
import ListCases from '../components/listCases';
import FadeText from '../components/fadeText';
import Footer from '../components/footer';
import ContactBlock from '../components/contactBlock';
import JobsBlock from '../components/jobsBlock';

import { ThemeContext } from '../context/theme-context';
import SingleCase from '../components/singleCase';

const IndexPage = ({ location, data }) => {
    return (
        <Layout bg="black" location={location}>
            <Helmet>
                <script
                    type="text/javascript"
                    src="https://www.google.com/jsapi?key=AIzaSyB6WinkbBnmcHxUCgp5g_lu6tyFigJrPwg"
                ></script>
                <title>ATUO - Comunicação e Experiência de Marca</title>
            </Helmet>
        
            <Hero />
            <Services />
            <SingleCase />
            <FadeText />

            <ThemeContext.Consumer>
                {(cx) => (
                    <ContactBlock
                        weather={cx?.cookies?.weather}
                    />
                )}
            </ThemeContext.Consumer>

            <JobsBlock />

            <Footer bg="black" />
        </Layout>
    );
};

export const query = graphql`
    query {
        allAtuoInsights(limit: 4, sort: { fields: [post_id], order: DESC }) {
            nodes {
                post_id
                slug
                title
                description
                post_date
                highlighted
            }
        }
    }
`;

export default IndexPage;
