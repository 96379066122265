  import React from 'react';
   

  const Hero = () => {

    return (
      <div
        data-aos="fade-in"
        data-aos-delay="200"
        className="hero bg-no-repeat bg-center bg-contain flex items-center justify-center"
      >
        
        <div className="container h-full flex flex-col hero-container">
          <div
            className="h-screen md:h-full text-4xl md:text-8xl font-semibold tracking-wide flex items-center pb-20 md:pb-0 md:my-20 first-text-hero"
            data-aos="fade-right"
            data-aos-delay="1000"
          >
            Inspiramos <br /> marcas e pessoas <br /> a transformar o <br />{" "}
            mundo
          </div>
          <div
            className="md:h-full text-2xl md:text-5xl uppercase flex items-center justify-end leading-tight md:leading-tight text-right pt-4 md:pt-0 md:my-20 second-text-hero"
            data-aos="fade-left"
            data-aos-delay="1450"
          >
            CONSTRUINDO <br /> EXPERIÊNCIAS QUE <br /> PROVOCAM OS <br />{" "}
            SENTIDOS
          </div>
        </div>
      </div>
    );
  };

export default Hero;
