import React from "react";

import LinkTransition from "./linkTransition";

const CardCase = ({ ...props }) => {
  return (
    <div
      data-aos="zoom-in"
      data-aos-mirror="false"
      data-aos-delay={props.delay}
    >
      <figure className="overflow-hidden">
        <LinkTransition to={props.link}>
          <img
            src={props.image}
            alt={props.title}
            className="transform transition duration-500 hover:scale-110"
          />
        </LinkTransition>
      </figure>

      <div>
        <h3 className="text-xl mt-4 mb-2 md:my-4 text-yellow">{props.title}</h3>

        <p className="text-lg mb-2 font-extralight leading-normal">
          {props.description}
        </p>

        <LinkTransition
          to={props.link}
          className="content-center justify-end hidden md:flex"
        ></LinkTransition>
      </div>
    </div>
  );
};

export default CardCase;
